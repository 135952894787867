<template>
    <!-- 新建banner页面 -->
    <div class="positionT0L0">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
            <a-form-model-item label="弹窗名称" prop="name" class="por">
                <a-input v-model="form.name" :maxLength="32" placeholder="请输入弹窗名称">
                    <div slot="addonAfter">{{ form.name.length }}/32</div>
                </a-input>
            </a-form-model-item>

            <a-form-model-item label="弹窗图片" :class="{ 'has-error': !form.imageUrl && isShowImag }" required>
                <UpdataImg @avatarfns="goodSentenceUrlFn" :imageUrlNum="1" :imgUrl="goodSentence" :sizeWh="1"></UpdataImg>
                <div class="ant-form-explain" v-if="!form.imageUrl && isShowImag">请上传弹窗图片</div>
            </a-form-model-item>

            <a-form-model-item ref="jumpType" label="跳转方式" prop="jumpType">
                <a-radio-group :value="form.jumpType" @change="jumpTypeChange" :disabled="isDisabled">
                    <a-radio :value="1">课程</a-radio>
                    <a-radio :value="5">小程序</a-radio>
                    <a-radio :value="4">会员详情</a-radio>
                    <a-radio :value="6">抖音</a-radio>
                    <a-radio :value="2">链接</a-radio>
                </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label="课程ID" prop="courseId" v-if="form.jumpType == 1">
                <a-input v-model="form.courseId" placeholder="请输入课程ID" :disabled="isDisabled"/>
            </a-form-model-item>

            <a-form-model-item ref="appid" label="小程序名称" prop="appid" v-if="form.jumpType == 5">
                <a-select v-model="form.appid" placeholder="请选择小程序" :disabled="isDisabled">
                    <a-select-option :value="item.id" v-for="item in appidList" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item ref="appPath" label="小程序跳转路径" prop="appPath" v-if="form.jumpType == 5">
                <a-input v-model="form.appPath" placeholder="请输入小程序跳转路径" :disabled="isDisabled"/>
            </a-form-model-item>

            <a-form-model-item label="抖音" prop="tiktokType" v-if="form.jumpType == 6">
                <a-select v-model="form.tiktokType" placeholder="请选择类型" :disabled="isDisabled">
                    <a-select-option :value="1">直播间</a-select-option>
                    <a-select-option :value="2">用户主页</a-select-option>
                </a-select>
            </a-form-model-item>
    
            <a-form-model-item label="ID" prop="targetText" v-if="form.jumpType == 6">
                <a-input v-model="form.targetText" placeholder="请输入抖音ID" :disabled="isDisabled"/>
            </a-form-model-item>

            <a-form-model-item label="链接" prop="targetText" v-if="form.jumpType == 2">
                <a-input v-model="form.targetText" placeholder="请输入链接" :disabled="isDisabled"/>
            </a-form-model-item>

            <a-form-model-item label="适用用户" prop="targetType">
                <a-radio-group name="targetType" :value="form.targetType" @change="targetTypeChange" :disabled="isDisabled">
                    <a-radio :value="1"> 全部用户 </a-radio>
                    <a-radio :value="2"> 指定用户 </a-radio>
                    <a-radio :value="3"> 指定商品用户 </a-radio>
                </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label="用户手机号" prop="phone" v-if="form.targetType == 2">
                <a-textarea v-model="form.phone" :rows="4" placeholder="输入用户手机号，多个用“,”分开" :disabled="isDisabled"/>
            </a-form-model-item>

            <a-form-model-item label="商品ID" prop="goodsId" v-if="form.targetType == 3">
                <a-textarea v-model="form.goodsId" :rows="4" placeholder="输入商品ID，多个商品ID用“,”分开" :disabled="isDisabled"/>
            </a-form-model-item>

            <a-form-model-item label="排序">
                <a-input-number v-model="form.sort" :min="0" placeholder="请输入排序" style="width:100%"/>
            </a-form-model-item>

            <a-form-model-item label="有效期" prop="dateTime">
                <a-range-picker v-model="form.dateTime" show-time @change="dateTimeChange"/>
            </a-form-model-item>

            <a-form-model-item ref="putawayFlag	" label="上架设置" prop="putawayFlag">
                <a-radio-group :value="form.putawayFlag" @change="putawayFlagChange">
                    <a-radio :value="2">立即上架</a-radio>
                    <a-radio :value="1">暂不上架</a-radio>
                </a-radio-group>
            </a-form-model-item>
        </a-form-model>

        <FooterToolBar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
        </FooterToolBar>
    </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import {GetHomeAdInfo, AddHomeAd} from "@/request/api/indexManage";
import UpdataImg from "@/components/file/updataImg";
import Ueditor from "@/components/ueditor/ueditor";
import { domainName } from "@/config/index";
import { appidList } from '@/utils/constants'
export default {
    mixins: [baseMixin],
    components: { FooterToolBar, Ueditor, UpdataImg },
    created() {
        this.url = `${domainName}/admin/files/upload/images`
        let id = this.$route.params.id;
        if (id !== "0") {
            this.isDisabled = true
            GetHomeAdInfo({ id: id }).then(({ code, data }) => {
                if (code == 200) {
                    this.form.name = data.name
                    this.form.goodsId = data.course && data.course.length ? data.course.toString() : ''
                    this.form.phone =  data.phone && data.phone.length ? data.phone.toString() : ''
                    this.form.imageUrl = data.images
                    this.goodSentence = data.images.split(",")
                    this.form.appid = data.app_id
                    this.form.sort = data.sort
                    this.form.jumpType = data.target_type
                    this.form.targetType = data.ad_type
                    this.form.courseId = data.business_no
                    this.form.dateTime = [data.start_time,data.end_time]
                    this.form.staTime = data.start_time
                    this.form.endTime = data.end_time
                    this.form.tiktokType = data.tiktokType
                    this.form.targetText = data.targetText
                    this.form.appPath = data.appPath
                }
            });
        }
    },
    data() {
        return {
            locale,
            url:'',
            fileList:[],
            goodSentence:[],
            isDisabled:false,
            goodsDetail: {}, // 商品详情
            keepLoading: false, // 保存的按钮是否加载
            coverOptions: {
                autoCrop: true, //是否默认生成截图框
                autoCropWidth: 325, //默认生成截图框宽度
                autoCropHeight: 500, //默认生成截图框高度
                fixedBox: true, //是否固定截图框大小 不允许改变
                previewsCircle: false, //预览图是否是原圆形
                title: "修改图片",
                original: false,
                outputType:'png'
            },
            form: {
                id: this.$route.params.id == 0 ? '' : this.$route.params.id,
                name: "",
                imageUrl: "",
                jumpType:1,
                courseId:'', // 课程id
                dithering:'', // 抖音id
                link:'', // h5链接
                appid:undefined,
                phone:'',
                goodsId:'',
                dateTime:[],
                sort:null,
                putawayFlag: 2,
                targetType:1,
                putawayTime:'',
                staTime:'',
                endTime:'',
                tiktokType:undefined,
                targetText: '',
                appPath: '',
            },
            rules: {
                name: [
                    {required: true, message: "输入的内容不能为空", trigger: "blur"},
                    {max: 32, message: "长度最长为32个", trigger: "blur"},
                ],
                jumpType: [
                    {required: true, message: "请选择跳转方式", trigger: "change"},
                ],
                courseId: [
                    {required: true, message: "请输入课程ID", trigger: "blur"},
                ],
                dithering: [
                    {required: true, message: "请输入抖音ID", trigger: "blur"},
                ],
                link: [
                    {required: true, message: "请输入链接", trigger: "blur"},
                ],
                appid: [
                    {required: true, message: "请选择小程序", trigger: "change"},
                ],
                targetType: [
                    {required: true, message: "请选择适用用户", trigger: "change"},
                ],
                phone: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                ],
                goodsId: [
                    { required: true, message: "输入商品ID", trigger: "blur" },
                ],
                dateTime: [
                    {required: true, message: "请选择有效期", trigger: "change"},
                ],
                putawayFlag:[
                    {required: true, message: "请选择上架设置", trigger: "change"},
                ],
                putawayTime:[
                    {required: true, message: "请选择有效期", trigger: "change"},
                ],
                tiktokType:[
                    {required: true, message: "请选择类型", trigger: "change"},
                ],
                targetText:[
                    {required: true, message: "请输入内容", trigger: "blur"},
                ],
                // sort:[
                //     {required: true, message: "请输入排序", trigger: "blur"},
                // ],
            },
            appidList,
            disabled:false,
            isShowImag:false,
        };
    },
    methods: {
        moment,
        dateTimeChange(val,dateString){
            console.log(val,dateString)
            this.form.staTime = dateString[0]
            this.form.endTime = dateString[1]
        },
    
        // 好词分享上传回调
        goodSentenceUrlFn(data) {
            this.goodSentence = data
            this.form.imageUrl = data.toString()
        },

        jumpTypeChange(e){
            this.form.targetText = ''
            this.form.jumpType = e.target.value;
        },

        targetTypeChange(e){
            this.form.targetType = e.target.value;
        },

        // 上架设置改变的函数
        putawayFlagChange(e) {
            this.form.putawayFlag = e.target.value;
        },

        // 保存的按钮
        keepClick() {
            this.keepLoading = true;

            setTimeout(() => {
                this.keepLoading = false;
            }, 3000);

            if (!this.form.imageUrl) {
                this.isShowImag = true;
                return false
            }

            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let obj = {
                        ad_no:this.form.id, // id
                        name:this.form.name, // 名称
                        images:this.form.imageUrl, // 图片
                        target_type:this.form.jumpType, // 跳转方式
                        business_no:this.form.courseId, // 课程id
                        app_id:!this.form.appid ? '' : this.form.appid, // 小程序id
                        putaway_flag:this.form.putawayFlag, // 上线状态
                        sort:this.form.sort, // 排序
                        ad_type:this.form.targetType, // 适用用户
                        phone:this.form.phone ? this.form.phone.replace(/，/ig,',').split(',') : [], // 手机号
                        course:this.form.goodsId ? this.form.goodsId.replace(/，/ig,',').split(',') : [], // 课程号
                        start_time:this.form.staTime, // 2022-08-14 00:00:00
                        end_time:this.form.endTime, // 2022-08-17 00:00:00
                        tiktokType:this.form.tiktokType,
                        targetText: this.form.targetText,
                        appPath: this.form.appPath,
                    }
                    console.log(obj)
                    AddHomeAd(obj).then(({code}) => {
                        if (code == 200) {
                            message.success("编辑成功");
                            location.href = "/indexManage/exercisePop";
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.ant-form-explain{
    margin-top: 4px;
}

.form_span {
    top: -4px;
}
/deep/.ant-radio-group {
    margin-bottom: 20px;
}
.product-box {
    position: relative;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 4px;
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    .product-wrap {
        height: 86px;
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            .product-info {
                flex-direction: column;
                height: 86px;
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                .p-price {
                    color: red;
                }
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
/deep/.product .ant-form-item-control {
    line-height: normal;
}
/deep/.edu-type .ant-form-item-children {
    display: flex;
}
/deep/.edu-type .ant-select:nth-child(2) {
    margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
    width: 100%;
}
/deep/.date-picker .ant-form-item-children {
    display: flex;
    align-items: center;
}
</style>
